export const spending_categories = [
    'Other',
    'Snacks',
    'Restaurant',
    'Clothes',
    'Gadgets',
    'Tools',
    'Entertainment',
];

export const payment_methods = [
    'Cash',
    'Debit Card/Interac',
    'Credit Card'
];
