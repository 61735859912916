import React from 'react';
import './styles.css';

const LoadingScreen = (props) => {
    return (
        // <div className="triple-spinner-container">
        //     <div className="triple-spinner"/>
        // </div>
        <>

            <div className="triple-spinner-container">
                <div className="triple-spinner"/>
            </div>
        </>
    );
};

export default LoadingScreen;
